import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import MetaWp from "../components/meta-wp"
import { default as SiteHeader } from "../components/header"

const Container = styled.article`
  padding-bottom: ${rem(120)};

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(40)};
  }
`

const Header = styled.header`
  ${props => props.theme.gridContainer()}

  margin-top: ${rem(40)};
  margin-bottom: ${rem(80)};
  text-align: center;

  @media ${props => props.theme.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(40)};
  }

  > div {
    max-width: ${rem(640)};
    margin: 0 auto;
  }
`

const Title = styled.h1.attrs({ className: `styled-h2` })``

const Image = styled.figure`
  ${props => props.theme.gridContainer(1152)}

  margin-bottom: ${rem(60)};

  @media ${props => props.theme.mediumDown} {
    margin-bottom: ${rem(20)};
  }

  > * {
    clip-path: polygon(
      ${rem(40)} 0%,
      100% 0%,
      calc(100% - ${rem(40)}) 100%,
      0% 100%
    );

    @media ${props => props.theme.mediumDown} {
      clip-path: polygon(
        ${rem(20)} 0%,
        100% 0%,
        calc(100% - ${rem(20)}) 100%,
        0% 100%
      );
    }
  }
`

const Content = styled.div`
  ${props => props.theme.gridContainer(960)}

  > div {
    max-width: ${rem(768)};
  }

  > div > p:first-child {
    font-size: ${rem(22)};

    @media ${props => props.theme.xxlargeDown} {
      font-size: ${rem(20)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(18)};
    }
  }
`

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`

const BasicTextTemplate = ({ data }) => {
  const { wp, metaWp } = data

  const {
    title,
    acf: { content, image, backgroundImage },
  } = wp

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      {backgroundImage && (
        <Background>
          {backgroundImage.localFile && (
            <Img
              fluid={backgroundImage.localFile.childImageSharp.fluid}
              role="presentation"
            />
          )}
        </Background>
      )}

      <SiteHeader />

      <Container>
        <Header>
          <div>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </Header>

        {image && image.localFile && (
          <Image>
            <Img fluid={image.localFile.childImageSharp.fluid} alt={title} />
          </Image>
        )}

        <Content>
          <div
            className="styled"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Content>
      </Container>
    </React.Fragment>
  )
}

BasicTextTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicTextTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPage

    ...AllPagesFragment
    ...AllPostsFragment
    ...AllCharactersFragment

    wp: wordpressPage(id: { eq: $id }) {
      ...PageBasicTextFragment
    }
  }
`
